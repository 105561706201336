<template lang="pug">
#edit-order(v-esc-close="cancel" :class="panelClass")
  icon.close-panel(data="@icon/x.svg" @click="cancel" width="16" height="16" title="Close")

  h1 {{ sliderTitle }}

  //- :schema="form._fromConfig"
  form(@submit.prevent="saveChanges")
    DynamicForm(
      :schema="formGroup"
      :disabled="readOnly"
      v-model="form"
      @status="saveStatus"
      ref="dynamicForm"
    )

    div(class="form-field")
      a#download-consent(v-if="this.consentUrl" :href="this.consentUrl")
        icon(data="@icon/download.svg")
        | Download Consent
    div(class="form-field")
      a#download-report(v-if="this.reportUrl" :href="this.reportUrl")
        icon(data="@icon/download.svg")
        | Download Report

    .panel-footer(v-if="readOnly")
      button.action-btn(type="button" @click="cancel") Close
    .panel-footer(v-else)
      button.cancel-btn(type="button" @click="cancel") Cancel
      button.action-btn(vtype="submit" @click.prevent="saveChanges") Save Changes
  </template>

<script>
import Patient from '@/models/patient.js'
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js'
import { mapState } from 'vuex'
import Vue from 'vue'

export default {
  props: {
    order: {
      type: Object,
      required: false,
      default: () => Patient.defaultOptions(),
    },
    readOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      reportUrl: '',
      consentUrl: '',
      panelClass: '',
      form: {},
      formGroup: {},
      genders: [
        {
          text: 'Female',
          value: 'F',
        },
        {
          text: 'Male',
          value: 'M',
        },
      ],
      status: null,
    }
  },

  computed: {
    sliderTitle,
    ...mapState({
      currentSku: state => state.skus.currentSku,
    }),
  },

  watch: {},

  created() {
    this.form = { ...new Patient(this.order.patient) }
    if (this.order.sku) {
      this.form.sku = this.order.sku
    }

    this.form.phone = parsePhoneNumber(this.form.phone).formatNational()

    let skuFields = []
    if (this.currentSku) {
      skuFields = this.currentSku.skus.map(s => {
        return { label: s.providerFriendlyDescription, value: s.id }
      })
    }
    const skuDropdown = {
      component: 'BaseSelect',
      label: 'Sku',
      halfWidth: false,
      options: skuFields ?? [],
      validations: {
        required: {
          params: null,
          message: 'A SKU is required',
        },
      },
    }

    skuDropdown.disabled = true

    Object.keys(this.form._fromConfig).forEach(key => {
      this.formGroup[key] = this.form._fromConfig[key]
    })
    this.formGroup['sku'] = skuDropdown
    console.log(skuDropdown)
  },

  async mounted() {
    if (this.reportReady()) {
      const res = await this.fetchReports()
      this.reportUrl = res?.data?.url
    }
    if (this.consentReady()) {
      const res = await this.fetchConsent()
      this.consentUrl = res?.data?.url
    }
    setTimeout(() => {
      this.panelClass = 'is-visible'
    }, 100)
  },

  methods: {
    cancel,
    saveChanges,
    saveStatus,
    fetchReports,
    fetchConsent,
    consentReady,
    reportReady,
  },

  components: {
    DynamicForm: require('@/components/DynamicForm').default,
  },
}

/* Computed ---------------------------------------------------- */
function sliderTitle() {
  return this.readOnly ? 'Order Details' : 'Edit order details'
}
/* Watch ------------------------------------------------------- */
/* Methods ----------------------------------------------------- */
function cancel() {
  this.panelClass = ''
  setTimeout(() => {
    this.$panel.close('EditOrder')
  }, 250)
}

function saveChanges() {
  this.$refs.dynamicForm.validate()

  if (this.status.invalid) {
    return
  }

  const asYouType = new AsYouType('US')
  asYouType.input(this.form.phone)

  this.form.phone = asYouType.getNumber().number

  this.$store.commit('orders/UPDATE_REVIEW_ORDER', this.form)
  this.cancel()
  this.$notyf.success('Changes saved')
}

function saveStatus(status) {
  this.status = status
}

async function fetchReports() {
  try {
    const response = await Vue.axios.get(`/reports?orderId=${this.order.id}`)
    return response
  } catch (error) {
    console.log(error)
    Vue.notyf.error('Something went wrong fetching the report. Please try again.')
    return error.response
  }
}

async function fetchConsent() {
  try {
    const response = await Vue.axios.get(`/consent?orderId=${this.order.id}`)
    return response
  } catch (error) {
    console.log(error)
    Vue.notyf.error('Something went wrong fetching consent. Please try again.')
    return error.response
  }
}

/**
 * we could make this available to the carrier earlier, but probably not needed.
 *
 */
function consentReady() {
  switch (this.order.status) {
    case 'LAB RESULTS SENT':
      return true
    case 'RESULTS AVAILABLE':
      return true
    default:
      return false
  }
}

function reportReady() {
  switch (this.order.status) {
    case 'LAB RESULTS SENT':
      return true
    case 'RESULTS AVAILABLE':
      return true
    default:
      return false
  }
}
</script>
